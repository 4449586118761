.App {
  text-align: center;
}

@import url(https://fonts.googleapis.com/css?family=Raleway:400,200,100);

* {
  margin:0;
  padding:0;
  overflow:hidden;
}
body {
  background-color:Black;
}
.a {
  background-color:rgba(0,0,0,0.1);
  position:absolute;
  top:50%;
  left:50%;
  width:500px;
  height:500px;
  margin-top:-250px;
  margin-left:-250px;
  border-radius:50%;
  border-top:50px solid rgba(255,255,255,0.5);
  border-left:50px solid rgba(255,255,255,0.5);
  -webkit-animation:my_animation 1.5s linear infinite;
  animation:my_animation 1.5s linear infinite;
}
.b {
  background-color:rgba(0,0,0,0.2);
  position:absolute;
  top:50%;
  left:50%;
  width:450px;
  height:450px;
  margin-top:-225px;
  margin-left:-225px;
  border-radius:50%;
  border-top:30px solid rgba(255,255,255,0.7);
  border-left:30px solid rgba(255,255,255,0.7);
  -webkit-animation:my_animation 1.3s linear infinite;
  animation:my_animation 1.3s linear infinite;
}
.c {
  background-color:rgba(0,0,0,0.3);
  position:absolute;
  top:50%;
  left:50%;
  width:400px;
  height:400px;
  margin-top:-200px;
  margin-left:-200px;
  border-radius:50%;
  border-top:20px solid rgba(255,255,255,0.2);
  border-left:20px solid rgba(255,255,255,0.2);
  -webkit-animation:my_animation 1.2s linear infinite;
  animation:my_animation 1.2s linear infinite;
}
.d {
  background-color:rgba(0,0,0,0.4);
  position:absolute;
  top:50%;
  left:50%;
  width:350px;
  height:350px;
  margin-top:-175px;
  margin-left:-175px;
  border-radius:50%;
  border-top:25px solid rgba(255,255,255,0.8);
  border-left:25px solid rgba(255,255,255,0.8);
  -webkit-animation:my_animation 1s linear infinite;
  animation:my_animation 1s linear infinite;
}
.e {
  background-color:rgba(0,0,0,0.5);
  position:absolute;
  top:50%;
  left:50%;
  width:300px;
  height:300px;
  margin-top:-150px;
  margin-left:-150px;
  border-radius:50%;
  border-top:15px solid rgba(255,255,255,0.4);
  border-left:15px solid rgba(255,255,255,0.4);
  -webkit-animation:my_animation 0.9s linear infinite;
  animation:my_animation 0.9s linear infinite;
}
.f {
  background-color:rgba(0,0,0,0.6);
  position:absolute;
  top:50%;
  left:50%;
  width:250px;
  height:250px;
  margin-top:-125px;
  margin-left:-125px;
  border-radius:50%;
  border-top:40px solid rgba(255,255,255,0.1);
  border-left:40px solid rgba(255,255,255,0.1);
  -webkit-animation:my_animation 0.8s linear infinite;
  animation:my_animation 0.8s linear infinite;
}
.g {
  background-color:rgba(0,0,0,0.7);
  position:absolute;
  top:50%;
  left:50%;
  width:200px;
  height:200px;
  margin-top:-100px;
  margin-left:-100px;
  border-radius:50%;
  border-top:25px solid rgba(255,255,255,0.3);
  border-left:25px solid rgba(255,255,255,0.3);
  -webkit-animation:my_animation 0.7s linear infinite;
  animation:my_animation 0.7s linear infinite;
}
.h {
  background-color:rgba(0,0,0,0.8);
  position:absolute;
  top:50%;
  left:50%;
  width:150px;
  height:150px;
  margin-top:-75px;
  margin-left:-75px;
  border-radius:50%;
  border-top:20px solid rgba(255,255,255,0.5);
  border-left:20px solid rgba(255,255,255,0.5);
  -webkit-animation:my_animation 0.5s linear infinite;
  animation:my_animation 0.5s linear infinite;
}
.i {
  background-color:rgba(0,0,0,0.9);
  position:absolute;
  top:50%;
  left:50%;
  width:100px;
  height:100px;
  margin-top:-50px;
  margin-left:-50px;
  border-radius:50%;
  border-top:15px solid rgba(255,255,255,0.1);
  border-left:15px solid rgba(255,255,255,0.1);
  -webkit-animation:my_animation 0.4s linear infinite;
  animation:my_animation 0.4s linear infinite;
}
.l {
  background-color:rgba(0,0,0,1);
  position:absolute;
  top:50%;
  left:50%;
  width:50px;
  height:50px;
  margin-top:-25px;
  margin-left:-25px;
  border-radius:50%;
  border-top:10 solid rgba(255,255,255,0.2);
  border-left:10px solid rgba(255,255,255,0.2);
  -webkit-animation:my_animation 0.3s linear infinite;
  animation:my_animation 0.3s linear infinite;
}

@-webkit-keyframes my_animation {
  from {-webkit-transform:rotate(0deg);
           -moz-transform:rotate(0deg);
            -ms-transform:rotate(0deg);}
  to {-webkit-transform:rotate(360deg);
         -moz-transform:rotate(360deg);
          -ms-transform:rotate(360deg);}
}
@keyframes my_animation {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}
